import React from 'react';
import { Helmet } from 'react-helmet';
import departementMSK from '../../assets/images/project__Departament_transport_MSK.png'
import fkSaturn from '../../assets/images/project__FK_Saturn.png'
import PAO_OAK_Pion from '../../assets/images/project__PAO_OAK-Pionerskaya.png'
import PAO_OAK_Sakh from '../../assets/images/project__PAO_OAK-Sacharova.png'
import rzhd from '../../assets/images/project__RZHD_Lokomotiv.png'
import AU__CHimki from '../../assets/images/project__AU_Arena_Chimki.png'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './projectsPage.scss';

const ProjectsPage: React.FC = () => {   
    return (
        <section className="projectsPage">
            <Helmet>
                <title>Наши Проекты - Реализации в области видеонаблюдения и систем безопасности</title>
                <meta name="description" content="Просмотрите наши успешные проекты в области видеонаблюдения и систем безопасности. Мы реализовали проекты для таких объектов, как Департамент транспорта Москвы, ФК «Сатурн», ПАО «ОАК» и другие." />
                <meta name="keywords" content="проекты, видеонаблюдение, системы безопасности, Департамент Транспорта Москвы, ФК Сатурн, ПАО ОАК, РЖД Арена, Арена Химки, проектирование, монтаж, обслуживание" />
            </Helmet>
            <h2 className="projectsPage__header">Наши реализации проектов на объектах</h2>

            <div className="projectPage">
                <div className="projectPage__text">
                    <h2 className="projectPage__header">Департамент Транспорта Москвы</h2>
                    <p className="projectPage__p">Пусконаладка переносного комплекса видеонаблюдения для системы телеобзора транспортной системы города Москвы.</p>
                </div>
                <img src={departementMSK} alt="photo" className="projectPage__img" />
            </div>
            <div className="projectPage">
                <div className="projectPage__text">
                    <h2 className="projectPage__header">ФК «Сатурн» Раменское</h2>
                    <p className="projectPage__p">МО, Раменское</p>
                    <p className="projectPage__p">Проектирование видеонаблюдения в соответствии с приказом МВД РФ №1092 от 17.11.2015 г. Разработка систем для обеспечения видеобезопасности в местах массового скопления людей. Техническое обслуживание компанией RSS по настоящее время.</p>
                </div>
                <img src={fkSaturn} alt="safe city" className="projectPage__img" />
            </div>
            <div className="projectPage">
                <div className="projectPage__text">
                    <h2 className="projectPage__header">ПАО «ОАК»</h2>
                    <p className="projectPage__p">Москва, ул. Большая Пионерская, д. 1</p>
                    <p className="projectPage__p">Техническое обслуживание комплекса инженерных средств охраны.</p>
                </div>
                <img src={PAO_OAK_Pion} alt="project" className="projectPage__img" />
            </div>
            <div className="projectPage">
                <div className="projectPage__text">
                    <h2 className="projectPage__header">ПАО «ОАК»</h2>
                    <p className="projectPage__p">Москва, ул. Проспект Академика Сахарова, д. 10.</p>
                    <p className="projectPage__p">Монтаж и пусконаладка систем безопасности и видеонаблюдения (субподряд).</p>
                </div>
                <img src={PAO_OAK_Sakh} alt="project project" className="projectPage__img" />
            </div>
            <div className="projectPage"> 
                <div className="projectPage__text">
                    <h2 className="projectPage__header">«РЖД Арена» ФК «Локомотив»</h2>
                    <p className="projectPage__p">Москва</p>
                    <p className="projectPage__p">Проектирование видеонаблюдения в соответствии с приказом МВД РФ №1092 от 17.11.2015 г. Разработка систем для обеспечения видеобезопасности в местах массового скопления людей. Техническое обслуживание компанией RSS по настоящее время.</p>
                </div>
                <img src={rzhd} alt="project" className="projectPage__img" />
            </div>
            <div className="projectPage">
                <div className="projectPage__text">
                    <h2 className="projectPage__header">АУ «Арена Химки»</h2>
                    <p className="projectPage__p">МО, Химки</p>
                    <p className="projectPage__p">Проектирование видеонаблюдения в соответствии с приказом МВД РФ №1092 от 17.11.2015 г. Разработка систем для обеспечения видеобезопасности в местах массового скопления людей. Техническое обслуживание компанией RSS по настоящее время.</p>
                </div>
                <img src={AU__CHimki} alt="project" className="projectPage__img" />
            </div>
        </section>
    )
}

export default ProjectsPage;