import { useLocation } from 'react-router-dom';
import camera from '../../assets/images/bg_maintenance.jpg'
import './maintenance.scss';

const Maintenance: React.FC = () => {

    return (
        <section className='maintenance'>
            <div className="container">
                 <h1 className="maintenance__header">Техническое обслуживание и ремонт систем видеонаблюдения Panomera Dallmeier electronic</h1>
                <div className='img-text'>
                    <img src={camera} alt="" />
                <div className="maintenance__services">
                        <p className="maintenance__services-text">Компания RSS осуществляет ремонт и техническое обслуживание систем видеонаблюдения Dallmeier.</p>
                        <p className="maintenance__services-text">Перечень оказываемых услуг:
                            <ul className="maintenance__services-ul">
                                <li>- проведение предупредительного и планового ремонта;</li>
                                <li>- демонтажные, монтажные и пусконаладочные работы;</li>
                                <li>- установка и настройка программного обеспечения Dallmeier;</li>
                                <li>- комплексная настройка систем на базе Dallmeier;</li>
                                <li>- техническое обслуживание и сервисное сопровождение.</li>
                            </ul>
                        </p>
                        <p className="maintenance__services-text">Наши специалисты обладают высоким уровнем квалификации, имеют все необходимые сертификаты и допуски для качественного проведения работ на объектах любой сложности</p>
                        <p className="maintenance__services-text">Оказываем услуги в Российской Федерации и странах СНГ.</p>
                        <p className="maintenance__services-text">На все выполняемые работы предоставляется гарантия.</p>
                </div>
                </div>
                
            </div>
        </section>
    )
}

export default Maintenance;