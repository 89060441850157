import { useEffect, useState} from 'react';
import logotype from '../../../assets/images/logo_logotype_main.png'
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './header.scss'

const Header: React.FC = () => {
    const location = useLocation();
    let classname: string = 'header '
    switch(location.pathname) {
        case '/': classname += 'home'
        break;
        default: classname += 'other'
    }

    const [isOpen, setOpen] = useState<boolean>(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowWidth = () => {
            setTimeout(() => {
              setWindowWidth(window.innerWidth);
            }, 400); 
          };
        
          window.addEventListener('resize', updateWindowWidth);
        
          return () => {
            window.removeEventListener('resize', updateWindowWidth);
          };
        }, []);

    return (
        <>
            {windowWidth >= 767 ? (
                <header className={classname}>
                    <div className="container">
                        <Link  to='/'><img src={logotype} alt="logotype" className="logotype" /></Link>
                        <nav className="nav">
                            <HashLink to='/#about' className="nav__link">О Компании</HashLink>
                            <Link to='/test-drive' className="nav__link">Тест драйв</Link>
                            <Link to='/solutions' className="nav__link">Решения</Link>
                            <Link to='/products' className="nav__link">Продукты</Link>
                            <Link to='/services' className="nav__link"> Услуги</Link>
                            <Link to='/projects' className="nav__link">Проекты</Link>
                            {/* <Link to='/news' className="nav__link">Новости</Link> */}
                            <Link to='/partners' className="nav__link">Партнеры</Link>
                            <HashLink to='/#footer' className="nav__link">Контакты</HashLink>
                        </nav>
                    </div>

                </header>):(<>
                {
                    isOpen ?  (
                        <>
                        <svg className='burger__nav-close' onClick={() => setOpen(!isOpen)} stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="2em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path></svg>
                        <nav className="burger__nav">                            
                        <Link to='/'className="burger__nav__link" onClick={() => setOpen(!isOpen)}>Главная</Link>
                        <HashLink to='/#about' className="burger__nav__link" onClick={() => setOpen(!isOpen)}>О Компании</HashLink>
                        <Link to='/test-drive' className="burger__nav__link" onClick={() => setOpen(!isOpen)}>Тест драйв</Link>
                        <Link to='/solutions' className="burger__nav__link" onClick={() => setOpen(!isOpen)}>Решения</Link>
                        <Link to='/products' className="burger__nav__link" onClick={() => setOpen(!isOpen)}>Продукты</Link>
                        <Link to='/services' className="burger__nav__link" onClick={() => setOpen(!isOpen)}> Услуги</Link>
                        <Link to='/projects' className="burger__nav__link" onClick={() => setOpen(!isOpen)}>Проекты</Link>
                        {/* <Link to='/news' className="burger__nav__link">Новости</Link> */}
                        <HashLink to='/#footer' className="burger__nav__link">Контакты</HashLink>
                        <Link to='/partners' className="burger__nav__link" onClick={() => setOpen(!isOpen)}>Наши партнеры</Link>
                        <a href="#" className="burger__nav__link" onClick={() => setOpen(!isOpen)}>support@rightsecurity.ru</a>
                        <a href="#" className="burger__nav__link" onClick={() => setOpen(!isOpen)}>info@rightsecurity.ru</a>
                        <a href="#" className="burger__nav__link" onClick={() => setOpen(!isOpen)}>+7(495) 532-30-75</a>
                    </nav>
                    </>
                    ) : (
                    <svg onClick={() => setOpen(!isOpen)} className='burger__open' stroke="currentColor" fill="currentColor" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>                 
                   )
                    
                }</>)
            } 
        </>         
    )
}

export default Header;