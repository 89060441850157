import { Helmet } from 'react-helmet';
import Maintenance from '../Maintenance/Maintenance';
import Match from '../components/match/Match';
import Accordion from './Accordion';
import { accordionData } from './accordionData';
import './services.scss'

const ServicesSection: React.FC = () => {
    return (
        <>
        <Helmet>
                <title>Услуги - Видеонаблюдение и Безопасность</title>
                <meta name="description" content="Предлагаем широкий спектр услуг: техническое обслуживание, ремонт систем видеонаблюдения, монтаж и интеграция систем, пожарная безопасность, звуковые системы и многое другое." />
                <meta name="keywords" content="Техническое обслуживание, Ремонт систем видеонаблюдения, Видеонаблюдение уличное, Монтаж, Интеграция, СКУД, Пожарная безопасность, Звуковые системы, Система аудиоконференции, Система видеоконференцсвязи, Система отображения информации, Система управления ситуационным центром, Система коммутации, Система электронного протоколирования, Система комплексной защиты информации, Защищенная видеосвязь, Конфиденциальная видеосвязь, Открытая видеосвязь с органами местного самоуправления, Подбор оборудования, Сопровождение матчей, Системы СКС, Wi-Fi точки, Проектирование видеонаблюдения" />
            </Helmet>
        <Maintenance/>  
        <Match/>
        <section className="services">
            <div className="container">
                <h1 className='services__header'>Услуги</h1>
            <div className='services__accordion'>
                {accordionData.map(({title, content}) => (
                    <Accordion title={title} content={content}/>
                ))}
            </div>
            </div>
            
        </section>
        </>
    )
}

export default ServicesSection;
;