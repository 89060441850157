import { Helmet } from 'react-helmet';
import wirenboard from '../../assets/images/partner-wirenboard.png';
import dallmeier from '../../assets/images/partner-dallmeier.png';
import './partners.scss';

const Partners: React.FC = () => {

    return (
        <section className="partners">

            <Helmet>
                <title>Партнеры - Dallmeier и WirenBoard</title>
                <meta name="description" content="Наши партнеры: Dallmeier - производитель камер и систем видеонаблюдения, Panomera, WirenBoard - российская компания по разработке электроники для автоматизации." />
                <meta name="keywords" content="Panomera, Dallmeier, Обслуживание, партнеры, видеонаблюдение, системы безопасности, автоматизация, WirenBoard" />
            </Helmet>
            <h1 className='partners__header'>Наши партнёры</h1>
            <div className="partner">
                <div className="partner__text">
                    <p>WirenBoard - российская компания, специализирующаяся на разработке и производстве электроники для автоматизации и мониторинга с 2012 года. Компания занимается созданием промышленных контроллеров на базе Linux и периферийных устройств с интерфейсом Modbus, таких как датчики, блоки ввода-вывода и счётчики.<br/>Каждое устройство проходит тщательное тестирование с использованием современного автоматизированного испытательного оборудования.<br/>Будьте уверены, что продукция Wiren Board представляет надежное и высокотехнологичное решение для вашей компании.</p>
                </div>
                <div className="partner__link">
                    <a href='https://wirenboard.com/ru/' className='partner__link-a'><img src={wirenboard} alt="logotype wirenboard" className='partner__link-img'/></a>
                    <a href="https://wirenboard.com"><p className='partner__link-a'>https://wirenboard.com</p></a>
                    <a href="tel:+74951506619"><p className='partner__link-a'>+7 (495) 150-66-19</p></a>   
                </div>
            </div>
            <div className="partner">
                <div className="partner__text">
                    <p>Компания основана Дитером Даллмайером в 1984 году в Регенсбурге. Уже в 1992 году на выставке в Эссене Даллмайер представляет свой первый цифровой регистратор на жестком диске. В настоящее время Компания Dallmeier со штаб-квартирой в Регенсбурге, Германия, разрабатывает и производит камеры и записывающие системы, а также все основные программные компоненты для современных систем видеонаблюдения, соответствующих GDPR. Продукция Dallmeier ипользуется для оснащения казино, проектов "умного города", аэропортов, объектов логистики, стадионов, промышленного сектора, а также для систем безопасности банков, объектов критической инфраструктуры и коммерческих организаций из всех отраслей.</p>
                </div>
                <div className="partner__link">
                    <a href='https://www.dallmeier.com' className='partner__link-a'><img src={dallmeier} alt="logotype wirenboard" className='partner__link-img'/></a>
                    <a href="https://www.dallmeier.com"><p className='partner__link-a'>https://www.dallmeier.com</p></a> 
                </div>
            </div>
        </section>
    )
}
export default Partners;