import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import departementMSK from '../../../assets/images/project__Departament_transport_MSK.png'
import fkSaturn from '../../../assets/images/project__FK_Saturn.png'
import PAO_OAK_Pion from '../../../assets/images/project__PAO_OAK-Pionerskaya.png'
import PAO_OAK_Sakh from '../../../assets/images/project__PAO_OAK-Sacharova.png'
import rzhd from '../../../assets/images/project__RZHD_Lokomotiv.png'
import AU__CHimki from '../../../assets/images/project__AU_Arena_Chimki.png'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './projects.scss';

const Projects: React.FC = () => {   
    return (
        <section className="projects">
            <h2 className="projects__header">Наши реализации проектов на объектах</h2>
            <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            >
                <SwiperSlide className='slide'>
                    <img src={departementMSK} alt="photo" className="photo" />
                    <div className="text">
                        <h2 className='text__header'>Департамент Транспорта Москвы</h2>
                        <p className='text__services'>Пусконаладка переносного комплекса видеонаблюдения для системы телеобзора транспортной системы города Москвы. </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide'>
                    <img src={fkSaturn} alt="photo" className="photo" />
                    <div className="text">
                    <h2 className='text__header'>ФК «Сатурн» Раменское</h2>
                    <p className='text__address'>МО, Раменское</p>
                    <p className='text__services'>Проектирование видеонаблюдения в соответствии с приказом МВД РФ №1092 от 17.11.2015 г. Разработка систем для обеспечения видеобезопасности в местах массового скопления людей. Техническое обслуживание компанией RSS по настоящее время.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide'>
                    <img src={PAO_OAK_Pion} alt="photo" className="photo" />
                    <div className="text">
                    <h2 className='text__header'>ПАО «ОАК»</h2>
                    <p className='text__address'>Москва, ул. Большая Пионерская, д. 1</p>
                    <p className='text__services'>Техническое обслуживание комплекса инженерных средств охраны.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide'>
                    <img src={PAO_OAK_Sakh} alt="photo" className="photo" />
                    <div className="text">
                    <h2 className='text__header'>ПАО «ОАК»</h2>
                    <p className='text__address'>Москва, ул. Проспект Академика Сахарова, д. 10.</p>
                    <p className='text__services'>Монтаж и пусконаладка систем безопасности и видеонаблюдения (субподряд).</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide'>
                    <img src={rzhd} alt="photo" className="photo" />
                    <div className="text">
                    <h2 className='text__header'>«РЖД Арена» ФК «Локомотив»</h2>
                    <p className='text__address'>Москва</p>
                    <p className='text__services'>Проектирование видеонаблюдения в соответствии с приказом МВД РФ №1092 от 17.11.2015 г. Разработка систем для обеспечения видеобезопасности в местах массового скопления людей. Техническое обслуживание компанией RSS по настоящее время.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide'>
                    <img src={AU__CHimki} alt="photo" className="photo" />
                    <div className="text">
                    <h2 className='text__header'>АУ «Арена Химки»</h2>
                    <p className='text__address'>МО, Химки</p>
                    <p className='text__services'>Проектирование видеонаблюдения в соответствии с приказом МВД РФ №1092 от 17.11.2015 г. Разработка систем для обеспечения видеобезопасности в местах массового скопления людей. Техническое обслуживание компанией RSS по настоящее время.</p>
                    </div>
                </SwiperSlide>


            </Swiper>
        </section>
    )
}

export default Projects;