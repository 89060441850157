import { useState } from "react";
import { IAccServices } from "../../models/AccordionServices";
import { HiOutlinePlus } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

const Accordion: React.FC<IAccServices> = ({title, content}) => {
    const [isActive, setIsActive] = useState<boolean>(false);

    return (
        <div className="accordion__item">
            <div className="accordion__title" onClick={() => setIsActive(!isActive)}>
                <div className="accordion__title-title">{title}</div>
                {isActive ? (<AiOutlineClose className="accordion__icon accordion__title-minus"/>) : (<HiOutlinePlus className="accordion__icon accordion__title-plus"/>)}
            </div>
            {isActive && <div className="accordion__content">{content}</div>}
        </div>
    )
}

export default Accordion;