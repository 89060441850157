import './benefits.scss';

const Benefits: React.FC = () => {
    return (
        <section className="benefits">
            <h2 className="benefits__header">Что получают наши клиенты</h2>
            <div className="benefits__all">
                <div className="benefit">
                    <h3>Решения <span>под ключ</span></h3>
                    <p>Больше не нужно нанимать несколько подрядчиков для решения разных задач. Мы берем на себя все, что касается безопасности объекта: от концепции и проектирования до ввода в эксплуатацию и обслуживания</p>
                </div>
                <div className="benefit">
                    <h3><span>Комплексный</span> подход</h3>
                    <p>Работа начинается с построения концепции безопасного объекта. Глубокий анализ потребностей и внедрение соответствующих технических систем позволяет добиться высокого уровня защиты в течение всего периода эксплуатации.</p>
                </div>
                <div className="benefit">
                    <h3>Подробная <span>документация</span></h3>
                    <p>Вместе с системами вы получаете комплект исполнительной документации по каждому комплексу, а также технические паспорта на продукты/системы, инструкции по эксплуатации для сотрудников и т. д.</p>
                </div>
                <div className="benefit">
                    <h3><span>Компетенция</span> по разным направлениям</h3>
                    <p>В нашей команде работают технические специалисты в различных направлениях безопасности и IT. Благодаря слаженной совместной работе они внедряют совершенные и высоконадежные системы.</p>
                </div>
                <div className="benefit">
                    <h3><span>Интеграция</span> всех систем и подсистем</h3>
                    <p>Мы не только проектируем и устанавливаем новые инженерные комплексы, но также интегрируем их между собой и с другими уже работающими на объекте автоматизированными системами.</p>
                </div>
                <div className="benefit">
                    <h3>Удобное и наглядное <span>BIM-планирование</span></h3>
                    <p>В ходе проектирования создается цифровая копия объекта, который оснащается виртуальной системой. Реалистичная модель позволяет добиться максимально эффективного решения.</p>
                </div>
            </div>
        </section>
    )
}

export default Benefits;