import { useEffect, useRef, useState } from 'react';

const AirPhotos: React.FC<{images: string[]}> = ({images}) => {
    const [blocked, setBlocked] = useState(false);
    const customBlockRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = (event: Event) => {
          if (blocked) {
            event.preventDefault();
            event.stopPropagation();
          }
        };

        const blockScroll = () => {
            setBlocked(true);
        };
      
        const unblockScroll = () => {
            setBlocked(false);
        };
    
        document.body.style.overflow = blocked ? 'hidden' : ''; 

        if (blocked) {
            document.addEventListener('scroll', handleScroll, { passive: false });
          } else {
            document.removeEventListener('scroll', handleScroll);
          }
      
          return () => {
            document.removeEventListener('scroll', handleScroll);
          };
        }, [blocked]);
    
    
    const [currentIndex, setCurrentImg] = useState(0);
    const handleScrollPhoto = (e: React.WheelEvent<HTMLDivElement>) => {
        const delta = e.deltaY;

        if (delta > 0) {
            setCurrentImg((prevIndex) => (prevIndex === images.length - 1 ? images.length - 1 : prevIndex + 1));
        } else {
            setCurrentImg((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
        }
    }
   
    useEffect(() => {
        const blockScroll = () => {
          setBlocked(true);
        };
    
        const unblockScroll = () => {
          setBlocked(false);
        };
    
        const handleMouseEnter = () => {
          blockScroll();
        };
    
        const handleMouseLeave = () => {
          unblockScroll();
        };
    
        if (customBlockRef.current) {
          customBlockRef.current.addEventListener('mouseenter', handleMouseEnter);
          customBlockRef.current.addEventListener('mouseleave', handleMouseLeave);
        }
    
        return () => {
          if (customBlockRef.current) {
            customBlockRef.current.removeEventListener('mouseenter', handleMouseEnter);
            customBlockRef.current.removeEventListener('mouseleave', handleMouseLeave);
          }
        };
      }, []);

    return (
        <div className='airports' onWheel={handleScrollPhoto}  ref={customBlockRef}>
        <div className='airports__slider' 
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index}`} />
          ))}
        </div>
      </div>
    )
}

export default AirPhotos;