import imgPolycamera from "../../assets/images/test-drive-polycamera.png";
import imgSecond1 from "../../assets/images/test-drive-second1.png";
import imgSecond2 from "../../assets/images/test-drive-second2.png";
import imgRecorder from "../../assets/images/test-drive-recorder.png";
import imgTopView from "../../assets/images/test-drive-top-view.png";
import imgSideView from "../../assets/images/test-drive-side-view.png";
import './TestDrive.scss';
import { Link } from "react-router-dom";

const TestDrivePage: React.FC = () => {
    return (
        <section className="test-drive">
            <div className="test-drive-start">
                <div className="test-drive-container">
                    <h1>Видеофиксация и видеоидентификация</h1>
                    <div className="test-drive-about">
                        <div className="test-drive-about-text">
                            <h2>Это задачи, которые часто требуют индивидуального
                                подхода и оперативных решений
                            </h2>
                            <h2>Если вы не уверены, какое оборудование
                                наилучшим образом удовлетворит
                                ваши потребности, предлагаем
                                воспользоваться нашей программой
                            </h2>
                            <a href="tel:+79252063075" className="btn__test" target="_blank">Протестировать</a>
                        </div>
                        <img src={imgPolycamera} alt="polycamera" className="test-drive-about-img" />
                    </div>
                    <div className="test-drive-title-container">
                        <h2 className="title">Test</h2>
                        <h2 className="subtitle">Drive</h2>
                        <div className="text">Познакомьтесь с функциональными возможностями,
                            а также оцените качество, надежность, и удобство работы
                            с предоставляемым нами оборудованием.</div>
                    </div>

                </div>
            </div>
            <div className="test-drive-second">
                <div className="test-drive-container">
                    <div className="solution">
                        <div className="solution__text ">
                            <p className="solution__p">С помощью программы вы сможете протестировать наши
                                системы видеофиксации и видеоидентификации и лично
                                убедиться в их качестве, надежности и
                                удобстве использования.</p>
                        </div>
                        <img src={imgSecond1} alt="Stadiums" className="solution__img" />
                    </div>
                    <div className="solution">
                        <div className="solution__text ">
                            <p className="solution__p">Наши специалисты помогут вам настроить оборудование,
                                подобрать оптимальные места для его размещения и
                                проконсультируют по всем вопросам.
                            </p>
                        </div>
                        <img src={imgSecond2} alt="safe city" className="solution__img" />
                    </div>

                </div>
            </div>
            <div className="test-drive-products">
                <div className="test-drive-products-title">
                    <h2>В настоящее время для проведения «тест-драйва» предоставляются
                        видеорекордер <span>Ommatid RSS 10000</span> и <span>полиматричная система Polycamera</span></h2>
                </div>
                <div className="test-drive-container">
                    <div className="test-drive-product">
                        <div className="test-drive-product-img">
                            <img src={imgRecorder} alt="" />
                            <Link to='/products' className="test-drive-product-link">Подробнее о продукте</Link>
                        </div>
                        <div className="test-drive-product-text">
                            <h3>Видеорекордер Ommatid RSS 10000</h3>
                            <p>Высокопроизводительное устройство, специально разработанное
                                для регистрации видео с множества каналов одновременно.
                                Видеорекордер способен обрабатывать до 120 видеоканалов
                                высокого разрешения в режиме реального времени, что делает его
                                незаменимым для крупных объектов с плотным
                                потоком видеоданных.
                            </p>
                            <p>Встроенная система хранения архива с поддержкой RAID 6
                                обеспечивает надежность и безопасность хранения данных.
                                При необходимости емкость может быть расширена с помощью
                                внешнего сервера хранения JBOD. Благодаря этим характеристикам,
                                Ommatid RSS 10000 идеально подходит для видеосистем,
                                установленных на объектах с повышенными требованиями к
                                безопасности и большими потоками данных.
                            </p>
                        </div>

                    </div>
                    <div className="test-drive-product">
                        <div className="test-drive-product-img">
                            <img src={imgPolycamera} alt="" />
                            <Link to='/products' className="test-drive-product-link">Подробнее о продукте</Link>
                        </div>
                        <div className="test-drive-product-text">
                            <h3>Polycamera</h3>
                            <p>Запатентованная камера, оснащенная усовершенствованной
                                комбинацией светочувствительных матриц. Эта технология
                                позволяет значительно снизить требования к инфраструктуре
                                видеонаблюдения, поскольку вместо нескольких камер,
                                охватывающих разные зоны, достаточно одной Polycamera
                                для покрытия больших площадей.</p>
                            <p>Камера транслирует изображение в режиме реального
                                времени с высоким разрешением, которое соответствует
                                строгим требованиям силовых структур к безопасности.
                                Polycamera оснащена системой кодирования, которая позволяет
                                проводить видеоанализ с использованием
                                искусственного интеллекта.
                                Это особенно эффективно на объектах, где требуется
                                классификация и анализ поведения большого количества
                                людей и объектов. Это особенно актуально для стадионов,
                                аэропортов, крупных торговых и бизнес-центров,
                                парков и промышленных зон.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="test-drive-models">
                <div className="test-drive-container">
                    <h2>Убедившись, что предоставляемое нами оборудование - идеальный
                        вариант для создания совершенной системы видеофиксации и
                        видеоидентификации, вы сможете заказать у нас разработку 3-D модели
                        оптимального размещения его на объекте с последующей поставкой и монтажом.
                    </h2>
                    <div className="test-drive-models-imgs">
                        <img src={imgTopView} alt="" />
                        <img src={imgSideView} alt="" />
                    </div>
                    <h2>А учитывая наш многолетний опыт работы и множество крупных объектов
                        «за плечами», думается, вы сделаете правильный выбор!
                    </h2>
                </div>
            </div>
        </section>
    )
}

export default TestDrivePage;