import { Helmet } from 'react-helmet';
import pdf from '../../assets/images/pdf.png'
import IPPoE from '../../assets/images/products__IP-PoE.png';
import IPDecoder from '../../assets/images/products__IP-decoder.png';
import APM from '../../assets/images/products__APM.png';
import IPCamera from '../../assets/images/products__IP-camera.png';
import Polycamera from '../../assets/images/products-polycamera.png';
import Recorder from '../../assets/images/test-drive-recorder.png';


import './products.scss';

const Products: React.FC = () => {

    return (
        <section className="products">
            <Helmet>
                <title>Продукты - Видеонаблюдение и Безопасность</title>
                <meta name="description" content="Наши продукты: мультифокальные камеры, IP видеодекодеры, IP PoE микрофоны. Закажите и купите камеры у нас." />
                <meta name="keywords" content="Мультифокальные камеры, IP видеодекодер для видеостены, IP PoE микрофон, Заказать камеры, Купить камеры" />
            </Helmet>
            <div className="Polycamera" id='Polycamera'>
                <h2 className="products__header">Наши продукты</h2>
                <div className="product">
                    <div className="product__header">
                        <h3 className="product__text-h3">Камеры Polycamera</h3>
                        <p className="product__text-p">Серия мультифокальных систем, 66 МП, 30 кадр/сек, H.264, H.265, день/ночь</p>
                    </div>

                    <div className="product__about">
                        <div className="product__about-photo">
                            <img src={Polycamera} alt="Camera Polycamera" />
                            <p>Используется для покрытия видеонаблюдением высокого разрешения огромных площадей, которые начинаются на большом удалении и являются пространственно широкими но короткими по расстоянию до камеры.</p>
                        </div>
                        <div className="product__about-text">
                            <ul>
                                <li>
                                    <h4>Разрешение и частота кадров</h4>
                                    <p>Высокое разрешение обеспечивает высокий динамический диапазон</p>
                                </li>
                                <li>
                                    <h4>Автоматическая калибровка</h4>
                                    <p>Оптимальная для видеоматриц высокого разрешения.</p>
                                </li>
                                <li>
                                    <h4>Поддержка ИИ</h4>
                                    <p>Кодирующая технология, которая позволяет проводить анализ видеоконтента в камере</p>
                                </li>
                                <li>
                                    <h4>Постоянная запись</h4>
                                    <p>Мультифокальная матричная система позволяет осуществить постоянную запись всей зоны наблюдения объекта с максимальным разрешением</p>
                                </li>
                                <li>
                                    <h4>EdgeStorage</h4>
                                    <p>Модули системы Polycamera S8 оснащены оперативной памятью,  которой постоянно сохраняются последние секунды видеопотока</p>
                                </li>
                                <li className='link-to-pdf'>
                                    <a href={require('./Polycamera.pdf')} download="Polycamera" target="_blank" rel="noreferrer">
                                        <img src={pdf} alt="icon pdf" />
                                        <p>Скачать файл с характеристиками устройства</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="IP-PoE" id='IP-PoE'>
                <div className="product">
                    <div className="product__header">
                        <h3 className="product__text-h3">Цифровой IP-PoE микрофон</h3>
                        <p className="product__text-p">Первый IP PoE микрофон с акустической линзой в России</p>
                    </div>

                    <div className="product__about">
                        <div className="product__about-photo">
                            <img src={IPPoE} alt="microphone IP-PoE" />
                            <p>Используется для записи в финансовой, судебной, общественной безопасности. <br />Применение: на режимных объектах, в пенитенциарных учреждениях, Государственная программа «Безопасный город», контроль уровня шума транспорта, на вокзалах и стадионах, а также в аэропортах. </p>
                        </div>
                        <div className="product__about-text">
                            <ul>
                                <li>
                                    <h4>Более высокий SNR (90 дБ)</h4>
                                    <p>Лучшее качество звука и меньший уровень шума</p>
                                </li>
                                <li>
                                    <h4>Герметичный корпус IPX7</h4>
                                    <p>Наши микрофоны могут быть установлены в помещении или на открытом воздухе</p>
                                </li>
                                <li>
                                    <h4>Модуль защиты от удара молнии</h4>
                                    <p>Защита от электростатического разряда и изменения полярности питания</p>
                                </li>
                                <li>
                                    <h4>Поддержка привязки конкретной модели IPC</h4>
                                    <p>Возможность реализации синхронизации аудио и видео на веб-странице</p>
                                </li>
                                <li>
                                    <h4>Возможность управления задержки аудио, в том числе синхронизация с видео</h4>
                                </li>
                                <li>
                                    <h4>Меньше проводов, подходит для съемки на дальних расстояниях</h4>
                                    <p>Не требуется протягивать аналоговый провод от видеокамеры до IP микрофона. Оснащен еще одним интерфейсом аналогового аудиовыхода</p>
                                </li>
                                <li className='link-to-pdf'>
                                    <a href={require('./IPPoE.pdf')} download="IP-PoE-Microphone" target="_blank" rel="noreferrer">
                                        <img src={pdf} alt="icon pdf" />
                                        <p>Скачать файл с характеристиками устройства</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="IP-decoder" id='IP-decoder'>
                <div className="product">
                    <div className="product__header">
                        <h3 className="product__text-h3">RSS 10000</h3>
                        <p className="product__text-p">Устройство с пакетом ПО для регистрации максимум программно-аппаратный комплекс
                            120 IP-каналов, 8 жестких дисков 3,5“, 3 юнита</p>
                    </div>

                    <div className="product__about">
                        <div className="product__about-photo">
                            <img src={Recorder} alt="IP video-decoder" />
                            <p>Сервер Ommatid RSS 10000 представляет собой надежное высокопроизводительное устройство с установленным специализированным ПО.
                                Оптимально подобранные компоненты гарантируют высокую скорость регистрации до 120 видео каналов высокого разрешения в режиме
                                реального времени. Встроенная система хранения архива c дисковым массивом RAID 6 может быть дополнительно расширена с помощью
                                внешнего сервера хранения JBOD. Данное устройство оптимально подходит для использования в крупных видеосистемах на стадионах, в
                                торговых и бизнес-центрах.
                            </p>
                        </div>
                        <div className="product__about-text">
                            <ul>
                                <li>
                                    <h4>Система хранения архива</h4>
                                    <p>На передней панели устройства Ommatid RSS 10000 располагаются легко доступные слоты (8 шт.) для жестких дисков. </p>
                                </li>
                                <li>
                                    <h4>Программное обеспечение</h4>
                                    <p>Приложение Ommatid RSS 10000 позволяет выполнять независимую и удобную работу с живым видео и архивом по сети Ethernet (LAN/WAN).</p>
                                </li>
                                <li>
                                    <h4>Удаленный доступ HD</h4>
                                    <p>Опциональная функция транскодирует видео высокого разрешения (живое или воспроизведение из архива) для дальнейшей его передачи по 
                                    сети с низкой пропускной способностью. </p>
                                </li>
                                <li>
                                    <h4>База Эйдж Аналитикс</h4>
                                </li>
                                <li>
                                    <h4>Смарт архиватор</h4>
                                    <p>Управление с любого места оператора</p>
                                </li>
                                <li>
                                    <h4>Открытая платформа</h4>
                                    <p>Предустановленное ПО разработано в качестве открытой платформы. </p>
                                </li>
                                <li className='link-to-pdf'>
                                    <a href={require('./RSS-10000.pdf')} download="RSS-10000" target="_blank" rel="noreferrer">
                                        <img src={pdf} alt="icon pdf" />
                                        <p>Скачать файл с характеристиками устройства</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="APM" id='APM'>
                <div className="product">
                    <div className="product__header">
                        <h3 className="product__text-h3">Рабочая АРМ станция</h3>
                        <p className="product__text-p">Высокопроизводительная рабочая станция предназначена для декодирования видеопотоков в системах видеонаблюдения.</p>
                    </div>

                    <div className="product__about">
                        <div className="product__about-photo">
                            <img src={APM} alt="APM camera" />
                            <p>Рабочая APM станция позволяет подключать до 8 мониторов и поддерживает работу с кодеками H.264 и H.265. Аппаратное декодирование видео обеспечивается высоко производительными комплектующими от корпорации nVIdia выполненными в промышленном исполнении.</p>
                        </div>
                        <div className="product__about-text">
                            <ul>
                                <li>
                                    <h4>Подключение до 8 мониторов</h4>
                                    <p>Разрешение до 4к - поддерживается аппаратное декодирование видеопотоков.</p>
                                </li>
                                <li>
                                    <h4>Аппаратное декодирование видео </h4>
                                </li>
                                <li>
                                    <h4>Современный 8-ядерный процессор с тактовой частотой 3.6 Гц</h4>
                                </li>
                                <li>
                                    <h4>Поддержка H.264 и H.265 кодеков </h4>
                                </li>
                                <li>
                                    <h4>Промышленное исполнение </h4>
                                    <p>Управление с любого места оператора</p>
                                </li>
                                <li className='link-to-pdf'>
                                    <a href={require('./APM.pdf')} download="APM" target="_blank" rel="noreferrer">
                                        <img src={pdf} alt="icon pdf" />
                                        <p>Скачать файл с характеристиками устройства</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/*             
            <div className="IP-camera" id='IP-camera'>
                <div className="product">
                    <div className="product__header">
                        <h3 className="product__text-h3">Купольная IP видеокамера</h3>
                        <p className="product__text-p">Камера высокой четкости, 2/5 Мп, H.265, день/ночь, VCA, HDR, PoE, варифокальный объектив с моторизированным приводом, купольный корпус </p>
                    </div>

                    <div className="product__about">
                        <div className="product__about-photo">
                            <img src={IPCamera} alt="IP camera" />
                            <p>Купольная камера для размещения на потолке или на стендовых декоративных поверхностях, с функцией переключения на режим «день-ночь», варифокальным объективом и возможностью настройки качества изображения, управления зумом, фокусировкой и диафрагмой. Идеально подходит для применения в учебных и спортивных учреждениях, ресторанах, казино, отелях, развлекательных заведениях.</p>
                        </div>
                        <div className="product__about-text">
                            <ul>
                                <li>
                                    <h4>4 видеопотока с разрешением до 5 МП</h4>
                                    <p>Позволяют добиться высокого качества изображения в условиях низкой освещенности.</p>
                                </li>
                                <li>
                                    <h4>Фокусное расстояние 3-10 мм,12-40 мм</h4>
                                    <p>Возможность управления фокусом</p>
                                </li>
                                <li>
                                    <h4>Возможность стилизации купола камеры под дизайн объекта</h4>
                                    <p>В наличии имеются различные цветовые гаммы куполов</p>
                                </li>
                                <li>
                                    <h4>Датчик освещенности</h4>
                                    <p>Автоматически корректирует настройки видеопотока</p>
                                </li>
                                <li>
                                    <h4>Встроенный модуль аналитики</h4>
                                    <p>Обнаружение оставленных предметов и проникновении в зону посторонних лиц</p>
                                </li>
                                <li className='link-to-pdf'>
                                    <a href={require('./IP-camera.pdf')} download="IP-camera" target="_blank" rel="noreferrer">
                                        <img src={pdf} alt="icon pdf" />
                                        <p>Скачать файл с характеристиками устройства</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    )
}

export default Products;