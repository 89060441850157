import technical from '../../../assets/images/match__technical.png';
import between from '../../../assets/images/match__between.png';
import pre from '../../../assets/images/match__pre-match.png';
import './match.scss';

const Match: React.FC = () => {
    return (
        <section className="match">
            <h2 className='match__header'>Сопровождение матчей</h2>
            <div className="match__preparation">
                <div className="match__preparation-technical match__preparation-item">
                    <img src={technical} alt="icon ball" className="icon" />
                    <h3>Техническое обслуживание</h3>
                    <ul>
                        <li>- контроль состояния системы видеонаблюдения;</li>
                        <li>- контроль настроек системы видеонаблюдения;</li>
                        <li>- механическая очистка от загрязнений оборудования;</li>
                        <li>- модульная диагностика неисправностей;</li>
                        <li>- выявление неисправностей системы видеонаблюдения, отчёт по выявленным неисправностям, которые невозможно устранить до начала матча;</li>
                        <li>- мониторинг состояния и обновлений, настройка операционной системы серверов;</li>
                        <li>- мониторинг состояния и обновление системы видеонаблюдения;</li>
                        <li>- тестирование работы функций системы новых версий;</li>
                        <li>- мониторинг состояния, модулей и функций системы видеонаблюдения;</li>
                        <li>- настройка системы согласно погодным условиям и режиму светового дня;</li>
                        <li>- создание резервных копий настроек и критически важных данных системы;</li>
                        <li>- фокусировка модулей и калибрация панорамного изображения;</li>
                        <li>- мониторинг общего состояния системы и ее функций (ping контроллеров видеокамер и видеорегистраторов в ручном режиме);</li>
                        <li>- тестовое включение записи с видеокамер;</li>
                        <li>- тестовая выгрузка фрагмента записи.</li>
                    </ul>
                </div>
                <div className="match__preparation-between match__preparation-item">
                    <img src={between} alt="icon ball" className="icon" />
                    <h3>Между матчевая подготовка</h3>
                    <ul>
                        <li>- контроль состояния оборудования и системы видеонаблюдения проводится в последний рабочий день перед матчем;</li>
                        <li>- выявление неисправностей оборудования и системы видеонаблюдения, отчёт по выявленным неисправностям, которые невозможно устранить до начала матча;</li>
                        <li>- мониторинг состояния и обновлений, настройка операционной системы серверов;</li>
                        <li>- мониторинг состояния и обновление системы видеонаблюдения;</li>
                        <li>- тестирование работы функций системы новых версий</li>                            
                        <li>- мониторинг состояния, модулей и функций подсистемы видеоаналитики системы видеонаблюдения;</li>
                        <li>- настройка системы согласно погодным условиям и режиму светового дня;</li>
                        <li>- создание резервных копий настроек и критически важных данных системы;</li>
                        <li>- фокусировка модулей и калибрация панорамного изображения;</li>
                        <li>- мониторинг общего состояния системы и ее функций (ping контроллеров видеокамер и видеорегистраторов в ручном режиме);</li>
                        <li>- тестовое включение записи с видеокамер;</li>
                        <li>-тестовая выгрузка фрагмента записи.</li>
                    </ul>
                </div>
                <div className="match__preparation-pre match__preparation-item">
                    <img src={pre} alt="icon foot" className="icon" />
                    <h3>Предматчевая подготовка</h3>
                    <ul>
                        <li>- начинается за 3 часа до начала матча;</li>
                        <li>- контроль настроек системы видеонаблюдения;</li>
                        <li>- проверка и настройка фокусировки;</li>
                        <li>- проверка и настройка сшивки;</li>
                        <li>- запуск записи видеокамер чаши за 2 часа до начала матча (до запуска зрителей на трибуны);</li>
                        <li>- ведение матча;</li>
                        <li>- контроль состояния видеозаписи, оборудования и системы видеонаблюдения;</li>
                        <li>- выгрузка по требованию ответственного сотрудника ЕЦУСа фрагментов записи по его указанию;</li>
                        <li>- по завершению матча и после покидания всех зрителей с трибун, завершение записи видеокамер чаши;</li>
                        <li>- перевод оборудования АРМ в режим ожидания.</li>
                    </ul>
                </div>
            </div>
        </section>
        
    )
}

export default Match;