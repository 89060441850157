import { Helmet } from 'react-helmet';
import city from '../../assets/images/solutions_city.jpeg'
import stadium from '../../assets/images/solutions_stadiums.jpg'
import rzhd from '../../assets/images/solutions_rzhd.jpg'
import './solutions.scss';
import AirPhotos from '../ProjectsPage/airports';
import data from '../ProjectsPage/data';

const Solutions: React.FC = () => {

    return (
        <section className="solutions">
                        <Helmet>
                <title>Решения по безопасности | Видеонаблюдение и контроль доступа</title>
                <meta name="description" content="Решения по обеспечению безопасности для городов, аэропортов, аэродромов, вокзалов и стадионов. Видеонаблюдение, контроль доступа, обнаружение взрывчатых устройств и другие системы безопасности." />
                <meta name="keywords" content="Безопасный город, Аэропорт безопасность, Аэродром безопасность, Видеонаблюдение для вокзала, Безопасность стадионов, СОТ, СКУД, системы обнаружения взрывчатых устройств, контроль доступа" />
            </Helmet>
            <div className="solution">
                <div className="solution__text ">
                    <h2 className="solution__header">Стадионы и Арены</h2>
                    <p className="solution__p">Обеспечение безопасности на стадионах с помощью следующих систем: <br/>1. СОТ и СКУД, которые помогают отслеживать движение людей и ограничивать доступ к определенным зонам.<br/> 2. Системы обнаружения взрывчатых устройств и металлодетекторы, для предотвращения попадания опасных предметов на стадион.</p>
                </div>
                <img src={stadium} alt="Stadiums" className="solution__img" />
            </div>
            <div className="solution">
                <div className="solution__text ">
                    <h2 className="solution__header">Безопасный город</h2>
                    <p className="solution__p">Безопасный город - комплекс технических средств контроля общественного порядка на территориях большой протяженности. Как правило, осуществляется в целях управления внутренних дел города. Включает средства видеоконтроля, оцифровки видеосигнала, сигнализации, мониторинга окружающей среды, доставки информации, распределения данных, оповещения.</p>
                </div>
                <img src={city} alt="safe city" className="solution__img" />
            </div>
            <div className="solution">
                <div className="solution__text ">
                    <h2 className="solution__header">Аэропорты и Аэродромы</h2>
                    <p className="solution__p">Одним из основных элементов являются контроль и проверка пассажиров, багажа и грузов, что включает в себя использование металлодетекторов, сканеров и рентгеновских аппаратов для обнаружения запрещенных предметов.</p>
                    <p className="solution__p">Другим важным аспектом являются видеонаблюдение, внедрение систем распознавания лиц для мониторинга и реагирования на потенциальные угрозы.</p>
                    <p className="solution__p">Также важным фактором являются строгая идентификация сотрудников аэропорта и контроль их действий, что позволяет предотвратить возможные угрозы со стороны внутренних лиц.</p>
                </div>
                {/* <img src={airport} alt="Airports" className="solution__img" /> */}
                <AirPhotos images={data}/>
            </div>
            <div className="solution">
                <div className="solution__text ">
                    <h2 className="solution__header">Ж/Д вокзалы</h2>
                    <p className="solution__p">Наша организация готова предложить решение самых важных компонентов в обеспечении безопасности на ж/д вокзалах:<br/>1. Видеонаблюдение. Установка камер на вокзале помогает следить за порядком и безопасностью на территории. Камеры используются для распознавания лиц злоумышленников, выявления проблемных ситуаций и предотвращения преступлений.<br/>2. Багажный контроль. Ввод АПК системы контроля багажа на вокзале (сканеры, металлоискатели, рентгеновские аппараты) помогает предотвращать незаконные перевозки опасных предметов или материалов.</p>
                </div>
                <img src={rzhd} alt="Train stations" className="solution__img" />
            </div>
        </section>
    )
}

export default Solutions;